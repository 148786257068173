import React, { useState, useEffect } from 'react';
import Template from '../components/Template';
import './contact.css';

const { REACT_APP_AIRTABLE_API_TOKEN } = process.env;

const Contact = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.airtable.com/v0/appXqP56p5bXn4bKa/Contact%20Information?maxRecords=3&view=Grid%20view",
          {
            headers: {
              'Authorization': `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
          const responseData = await response.json();
          setData(responseData.records);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();

    const handleScroll = () => {
      const scroll = window.scrollY;
      const zoomContainer = document.querySelector('.banner-container');
      let initialZoom = 170; // Default initial zoom level
      let zoomFactor = 15;

      // Check if screen size is that of a phone
      if (window.innerWidth <= 600) {
        initialZoom = 400; // Set initial zoom level to 500 for phones
        zoomFactor = 30;
      }
      const newSize = Math.max(initialZoom - scroll / zoomFactor, 100); // Calculate the new background size for zoom-out
      const newTop = -(scroll / 10); // Calculate the new top position for zoom-out

      zoomContainer.style.backgroundSize = `${newSize}%`;
      zoomContainer.style.top = `${newTop}%`;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <Template>
      <div className="container">
        <div className="banner-container"></div>
        <div className="contact-container">
          <h1 className="center">Contact Information</h1>
          <div className="contacts">
            {data.map((records) => (
              <div key={records.id} className="contact-person">
                <h3>{records.fields.Name}</h3>
                <p>{records.fields.Title1}</p>
                <p>{records.fields.Title2}</p>
                <p><a href={`mailto:${records.fields.Email}`} style={{ textDecoration: 'none', color: '#6e9fc1' }}>{records.fields.Email}</a></p>
              </div>
            ))}
          </div>
          <div className="location borders">

            <iframe className="location-col"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.159255318796!2d-122.17829943445699!3d37.433342437107726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fa4d28c152c13%3A0x156754405adbcd7a!2s269%20Campus%20Drive%2C%20Palo%20Alto%2C%20CA%2094305!5e0!3m2!1sen!2sus!4v1706766196525!5m2!1sen!2sus"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="Stanford Lab Location"
            ></iframe>
            <div className="location-info-col">
              <h3>Stanford Lab</h3>
              <p>CCSR 2230<br></br>269 Campus Dr<br></br>Stanford, CA 94305</p>
            </div>
          </div>
          <div className="location">
            <iframe className="location-col"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.3546020227873!2d-122.14278992417367!3d37.405092633369215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fba9b6a76c905%3A0xdbeeb9a12c21c7a0!2sPalo%20Alto%20VA%20Medical%20Center!5e0!3m2!1sen!2sus!4v1706196731675!5m2!1sen!2sus&zoom=5"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="VA Lab Location"
            ></iframe>
            <div className="location-info-col">
              <h3>VA Lab</h3>
              <p>Building 710, Room 2-301<br></br>3801 Miranda Ave<br></br>Palo Alto, CA 94304</p>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Contact;
