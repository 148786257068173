import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import highlightedPubs from '../data/highlightedPub.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

function PubCard() {
    const [showModal, setShowModal] = useState(false);
    const [abstract, setAbstract] = useState('');
    const [selectedPub, setSelectedPub] = useState(null);
    const [animationIndex, setAnimationIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationIndex(prevIndex => {
                if (prevIndex === highlightedPubs.length - 1) {
                    clearInterval(interval); // Stop interval after all cards have animated in
                }
                return prevIndex + 1;
            });
        }, 200); // Adjust the delay between each card animation as needed
        return () => clearInterval(interval); // Clear interval on component unmount
    }, []);

    const handleShowModal = (abstract, pub) => {
        setAbstract(abstract);
        setSelectedPub(pub);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [expandedAuthors, setExpandedAuthors] = useState(Array(highlightedPubs.length).fill(false));

    const handleToggleAuthors = (index) => {
        const newExpandedAuthors = [...expandedAuthors];
        newExpandedAuthors[index] = !newExpandedAuthors[index];
        setExpandedAuthors(newExpandedAuthors);
    };

    return (
        <div className="card-container">
            {highlightedPubs.map((pub, index) => (
                <Card key={index} className={`custom-card ${index <= animationIndex ? 'fade-in' : ''}`}>
                    <div className="card-img-wrapper">
                        <Card.Img src={pub.img} alt={pub.alt} />
                    </div>
                    <Card.Body>
                        <Card.Title>
                            <a target="_blank" rel="noopener noreferrer" href={pub.pubMed}>
                                {pub.title}
                            </a>
                        </Card.Title>
                        <Card.Text className="card-text">
                            in <i>{pub.journal}</i>, {pub.miscInfo}
                            <br />
                            <br />
                            {expandedAuthors[index] || pub.authors.split(',').length <= 3 ? (
                                pub.authors
                            ) : (
                                <>
                                    {pub.authors.split(',').slice(0, 3).join(', ')}
                                    {' '}
                                    <Button
                                        variant="link"
                                        onClick={() => handleToggleAuthors(index)}
                                        className="expand-button"
                                    >
                                        [ + ]
                                    </Button>
                                </>
                            )}
                            {expandedAuthors[index] && (
                                <>
                                    <br/>
                                    <Button
                                        variant="link"
                                        onClick={() => handleToggleAuthors(index)}
                                        className="collapse-button"
                                    >
                                        [ - ]
                                    </Button>
                                </>
                            )}
                        </Card.Text>
                        <div className="button-div">
                            <Button
                                className="btn-secondary"
                                variant="primary"
                                onClick={() => handleShowModal(pub.abstract, pub)} // Pass the pub object
                            >
                                Read Abstract
                            </Button>
                            <Button
                                className="btn-pdf"
                                variant="primary"
                                href={pub.download}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faFilePdf} />
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            ))}
            {selectedPub && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title><b>{selectedPub.title}</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{abstract}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default PubCard;
