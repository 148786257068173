import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import RobinsonLogo from '../images/RobinsonLabLogo.svg';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
library.add( faBars, faTimes);

const Navbar = () => {
  const location = useLocation();
  const [isFrosted, setIsFrosted] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  

  useEffect(() => {
    const handleScroll = () => {
      const scrollStart = 30; // Adjust as needed
      const shouldFrost = window.scrollY > scrollStart;

      setIsFrosted(shouldFrost);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav>
        <div className={` ${isFrosted ? 'nav-fade-in' : ''} nav-bar`}>
          <Link to="/">
            <img id="name-logo" src={RobinsonLogo} alt="Name Logo" />
          </Link>
          <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'white-icon' : 'black-icon'}`} onClick={toggleMobileMenu}>
                  <FontAwesomeIcon icon={isMobileMenuOpen ? 'times' : ['fas', 'bars']} />
          </div>
          <ul className={`navbar-menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
            <li>
              <Link to="/research" className={location.pathname === '/research' ? 'active' : ''}>
                Research
              </Link>
            </li>
            <li>
              <Link to="/team" className={location.pathname === '/team' ? 'active' : ''}>
                Team
              </Link>
            </li>
            <li>
              <Link to="/publications" className={location.pathname === '/publications' ? 'active' : ''}>
                Publications
              </Link>
            </li>
            <li>
              <Link to="/gallery" className={location.pathname === '/gallery' ? 'active' : ''}>
                Gallery
              </Link>
            </li>
            <li>
              <Link to="/join-us" className={location.pathname === '/join-us' ? 'active' : ''}>
                Join Us
              </Link>
            </li>
            <li>
              <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>
                Contact
              </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
