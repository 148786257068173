import React from "react";
import SoMLogo from "../images/SoM-logo.png"

const Footer = () => {
    return (
        <footer>
            <div>
                <p><b>ROBINSON LAB @ STANFORD UNIVERSITY</b></p>
                <p>Division of Immunology and Rheumatology</p>
            </div>
            <div>
                <img src={SoMLogo} alt="Stanford School of Medicine Logo" className="som-logo"></img>
            </div>
        </footer>
    );
};

export default Footer;
