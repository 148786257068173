const highlightedPubs = [
    {
      title: "Clonally Expanded B Cells in Multiple Sclerosis Bind EBV EBNA1 and GlialCAM",
      authors: "by Lanz TV, Brewer RC, Ho PP, Moon J-S, Jude KM, Fernandez D, Fernandez RA, Gomez A, Nadj G, Bartley CM, Schubert RD, Hawes IA, Vazques SE, Iyer M, Zchero JB, Teegen B, Dunn JE, Lock CB, Kipp LB, Cotam VC, Ueberheide B, Aftab BT, Anderson MS, DeRisi JL, Wilson MR, Bashfort RJM, Platten M, Garcia KC, Steinman L, Robinson WH.",
      abstract: "Epstein-Barr virus (EBV) infection is associated with several human autoimmune diseases, and we are investigating it association with multiple sclerosis (MS). MS is a heterogenous autoimmune disease in which autoreactive lymphocytes attack the myelin sheath of the central nervous system (CNS). B lymphocytes in the cerebrospinal fluid (CSF) of MS patients contribute to inflammation and secrete oligoclonal immunoglobulins. EBV infection has been linked to MS epidemiologically, but its pathological role remains unclear. Here we demonstrate high-affinity molecular mimicry between the EBV transcription factor EBNA1 and the CNS protein GlialCAM, and provide structural and in-vivo functional evidence for its relevance. A cross-reactive CSF-derived antibody was initially identified by single-cell sequencing of the paired-chain B cell repertoire of MS blood and CSF, followed by protein microarray-based testing of recombinantly expressed CSF-derived antibodies against MS-associated viruses. Sequence analysis, affinity measurements, and the crystal structure of the EBNA1-peptide epitope in complex with the autoreactive Fab fragment allowed for tracking the development of the naïve EBNA1-restricted antibody to a mature EBNA1/GlialCAM cross-reactive antibody. Molecular mimicry is facilitated by a post-translational modification of GlialCAM. EBNA1 immunization exacerbates the mouse model of MS and anti-EBNA1/GlialCAM antibodies are prevalent in MS patients. Our results provide a mechanistic link for the association between MS and EBV, and could guide the development of novel MS therapies.",
      pubMed: "https://pubmed.ncbi.nlm.nih.gov/35073561/",
      download: "https://med.stanford.edu/content/dam/sm/robinsonlab/documents/238-2.pdf",
      img: "/images/Highlight_1.png",
      alt: "highlight 1",
      journal: "Nature",
      miscInfo: "603:321-327, 2022. PMCID: PMC9382663."
    },
    {
      title: "Oral mucosal breaks trigger anti-citrullinated bacterial and human protein antibody responses in rheumatoid arthritis",
      authors: "by Brewer RC, Lanz TV, Hale CR, Sepich-Poore GD, Martino C, Swafford AD, Carroll TS, Kongpachith S, Blum LK, Elliott SE, Blachere NE, Parveen S, Fak J, Yao V, Troyanskaya O, Frank MO, Bloom SM, Jahanbani S, Gomez AM, Iyer R, Ramadoss NS, Sharpe O, Chandrasekaran S, Kelmenson LB, Wang Q, Wong H, Torres HL, Wiesen M, Graves DT, Deane KD, Holers VM, Knight R, Darnell RB, Robinson WH*, Orange DE. (*co-corresponding).",
      abstract: "Periodontal disease is more common in individuals with rheumatoid arthritis (RA) who have detectable anti-citrullinated protein antibodies (ACPA), implicating oral mucosal inflammation in RA pathogenesis. Here, we performed paired analysis of human and bacterial transcriptomics in longitudinal blood samples from RA patients. We discovered that patients with RA and periodontal disease experienced repeated oral bacteremias associated with transcriptional signatures of ISG15+HLADRhi and CD48highS100A2pos monocytes, recently identified in inflamed RA synovium, and blood of RA flares. The oral bacteria observed transiently in blood were broadly citrullinated in the mouth, and their in situ citrullinated epitopes were targeted by extensively somatically hypermutated ACPA encoded by RA blood plasmablasts. Together, these results suggest (i) periodontal disease results in repeated breaches of the oral mucosa that release citrullinated oral bacteria into circulation, which (ii) activate inflammatory monocyte subsets that are observed in inflamed RA synovium and blood of RA patients with flares, and (iii) activate ACPA B cells, thereby promoting affinity maturation and epitope spreading to citrullinated human antigens",
      pubMed: "https://pubmed.ncbi.nlm.nih.gov/36812347/",
      download: "https://med.stanford.edu/content/dam/sm/robinsonlab/documents/252.pdf",
      img: "/images/Highlight_2.bmp",
      alt: "highlight 2",
      journal: "Science Translational Medicine", 
      miscInfo: "2023. 15(684):eabq8476. PMCID: PMC9852471."
    },
    {
        title: "Cytotoxic CD8+ T cells target citrullinated antigens in rheumatoid arthritis",
        authors: "by Moon JS*, Younis S*, Ramadoss NS, Iyer R, Sheth K, Sharpe O, Rao NL, Becart S, Carman JA, James EA, Buckner JH, Deane KD, Holers VM, Goodman SM, Donlin LT, Davis MM, Robinson WH. (co-first authors)",
        abstract: "The immune mechanisms that mediate synovitis and joint destruction in rheumatoid arthritis (RA) remain poorly defined. Although increased levels of CD8+ T cells have been described in RA, their role in pathogenesis remains unclear. Here we performed single cell transcriptome and T cell receptor (TCR) sequencing of CD8+ T cells derived from RA blood. We identified GZMB+CD8+ subpopulations containing large clonal lineage expansions that express cytotoxic and tissue homing transcriptional programs, while a GZMK+CD8+ memory subpopulation comprised of smaller clonal expansions that express effector T cell transcriptional programs. We demonstrated RA citrullinated autoantigens presented by MHC class I activate RA blood-derived GZMB+CD8+ T cells to expand, express cytotoxic mediators, and mediate killing of target cells. We also demonstrated that these clonally expanded GZMB+CD8+ cells are present in RA synovium. These findings suggest that cytotoxic CD8+ T cells that target citrullinated antigens contribute to synovitis and joint tissue destruction in ACPA+ RA.",
        pubMed: "https://pubmed.ncbi.nlm.nih.gov/36658110/",
        download: "https://med.stanford.edu/content/dam/sm/robinsonlab/documents/251.pdf",
        img: "/images/Highlight_3.bmp",
        alt: "highlight 3", 
        journal: "Nature Communications",
        miscInfo: "2023, 14(1):319. PMCID: PMC9852471"
      },
  ];
  
  export default highlightedPubs;
  