import React, { useEffect } from 'react';
import Template from '../components/Template';
import './join-us.css';

const Contact = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      const zoomContainer = document.querySelector('.banner-container-join-us');
      let initialZoom = 120; // Default initial zoom level
      let zoomFactor = 15;

      // Check if screen size is that of a phone
      if (window.innerWidth <= 600) {
        initialZoom = 200; // Set initial zoom level to 500 for phones
        zoomFactor = 20;
      }
      const newSize = Math.max(initialZoom - scroll / zoomFactor, 100); // Calculate the new background size for zoom-out
      const newTop = -(scroll / 10); // Calculate the new top position for zoom-out

      zoomContainer.style.backgroundSize = `${newSize}%`;
      zoomContainer.style.top = `${newTop}%`;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Template>
      <div className="container">
        <div className="banner-container-join-us"></div>
        <div className="contact-container">
          <h1 className="center">Join Us</h1>
          <p className="center sm-bottom-margin balanced-p">The Robinson Lab seeks innovative and motivated researchers at all levels. If you are intrigued by the molecular and cellular mechanisms of autoimmune diseases, and want to contribute to shaping a diverse, inclusive, and dynamic lab environment, we would be thrilled to have you join us!</p>
          <div className="border-top">
          <h3 className="">Postdoctoral Positions</h3>
          <p className="sm-bottom-margin">Our lab has occasional openings for highly-driven postdoctoral fellows. Experience in immunology, genomics, cell biology, biochemistry, informatics, or a related field is desired. Interested applicants should send their cover letter and CV to <a href="mailto:wrobins@stanford.edu" target="_blank" rel="noopener noreferrer">wrobins@stanford.edu</a>.</p>
          <h3 className="">Graduate Students</h3>
          <p>If you are interested in joining our laboratory, please apply directly to the <a href="https://med.stanford.edu/immunol/phd-program.html" target="_blank" rel="noopener noreferrer">Stanford Immunology PhD Program</a> or another <a href="https://biosciences.stanford.edu" target="_blank" rel="noopener noreferrer">Stanford Biosciences PhD Program</a>. Once a Stanford PhD student, you can contact Dr. Robinson to coordinate a rotation.</p>
        </div>
        </div>
      </div>
    </Template>
  );
};

export default Contact;
