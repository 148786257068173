import React, { useEffect } from 'react';
import Template from '../components/Template';
import { Link } from 'react-router-dom';
import './home.css';
import TeamPhoto from "../images/main-team-photo.jpg"

const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      const zoomContainer = document.querySelector('.home-container');
      let initialZoom = 100; // Default initial zoom level

      // Check if screen size is that of a phone
      if (window.innerWidth <= 600) {
        initialZoom = 350; // Set initial zoom level to 500 for phones
      }
      zoomContainer.style.backgroundSize = `${initialZoom + scroll / 5}%`;
      zoomContainer.style.top = `-${scroll / 10}%`;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Template>
      <div className="home-container">
        <div className="home-description">
          <h1 className="larger-h1">We investigate autoimmune diseases.</h1>
          <p>
            The Robinson Laboratory uses interdisciplinary approaches in genomics, proteomics, and bioinformatics to investigate autoimmune diseases, with a focus on elucidating the mechanisms that underlie the development and progression of autoimmunity. We are defining microbial triggers of autoimmunity, including the role of EBV. We strive to translate our findings into next-generation therapeutics, and 5 therapeutic programs have arisen from work performed in our lab.
          </p>
          <Link to="/research">
            <button className="styled-button">Explore Our Research</button>
          </Link>
        </div>
      </div>
      <div className="home-body">
        <div className="home-content">
          <h1>The Robinson Lab</h1>
          <img src={TeamPhoto} alt="Team" className="team-photo"></img>
          <Link to="/team">
            <button className="styled-button-black">Meet Our Team</button>
          </Link>
        </div>
      </div>
    </Template>
  );
};

export default Home;
